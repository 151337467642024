import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Avatar, Button, Collapse, Descriptions } from 'antd'
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined'
import Date from 'components/Format/Date/Date'
import Currency from 'components/Format/Currency/Currency'
import AccountNumber from 'components/Format/AccountNumber/AccountNumber'
import Loader from 'components/Loader/Loader'
import { useLanguageState } from 'stores/language/LanguageStore'
import { pi, action } from 'lang/definitions/index'
import { getAttachment, getPaymentInstructionTemplate } from 'api/paymentInstruction'
import { getProfileDetails } from 'api/user'
import { PaymentInstructionTemplate, Interval, Attachment } from 'types/paymentInstruction'
import { Profile } from 'types/profile'
import { Approver } from 'types/user'
import ACL from 'components/ACL/ACL'
import { getInitials, stringToColor } from 'components/BHAvatar/utils'
import PaymentState from './components/PaymentState/PaymentState'
import { getEntityName } from 'utils/helpers'
import usePaymentDetailsStyle from './PaymentInstructionDetails.style'
import { cx } from 'antd-style'

interface PaymentInstructionTemplateDetailsProps {
  templateId: string
  isOverview?: boolean
  handleRemovePayment?: (id: string) => void
}

const PaymentInstructionTemplateDetails = ({
  templateId,
  handleRemovePayment,
  isOverview,
}: PaymentInstructionTemplateDetailsProps): React.JSX.Element => {
  const [languageState] = useLanguageState()
  const language = languageState.language
  const intl = useIntl()
  const { styles } = usePaymentDetailsStyle()
  const { Panel } = Collapse

  const [profile, setProfile] = useState<Profile>()
  const [template, setTemplate] = useState<PaymentInstructionTemplate>()
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (templateId) {
      void getData(templateId)
    }
  }, [templateId])

  const getData = async (templateId: string) => {
    try {
      setShowLoader(true)
      const template = await getPaymentInstructionTemplate(templateId)
      setTemplate(template)
      if (template) {
        const { profile } = await getProfileDetails(template.profileId)
        setProfile(profile)
      }
      setShowLoader(false)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getIntervalString = (interval: Interval): string => {
    if (interval.unit === 'days') {
      if (interval.value === 7) {
        return intl.formatMessage(pi['pi.paymentsFrequency.option.everyWeek'])
      } else {
        return intl.formatMessage(pi['pi.paymentsFrequency.option.every2Weeks'])
      }
    } else {
      if (interval.value === 1) {
        return intl.formatMessage(pi['pi.paymentsFrequency.option.everyMonth'])
      }
      return intl.formatMessage(pi['pi.paymentsFrequency.option.every2Months'])
    }
  }

  const handleDownloadAttachment = async (piTemplateId: string, attachmentId: string): Promise<void> => {
    try {
      const response = await getAttachment(piTemplateId, attachmentId)

      const fileName = template?.paymentInstructionProperties.attachments![0].filename

      const urls = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = urls
      link.setAttribute('download', `${fileName!}`)
      link.click()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  return (
    <Loader showLoader={showLoader}>
      {template && (
        <div className={cx(styles.paymentDetails, styles.dropdownFilterWrapper)} data-testid="payment-template-wrapper">
          <Collapse expandIconPosition="end" className={styles.dropdownFilter} defaultActiveKey={[1, 2, 3, 4, 5, 6, 7]}>
            <Panel header={intl.formatMessage(pi['pi.details.payment'])} key="1" className={styles.filterPanel}>
              <Descriptions bordered size="small" className={styles.noBorder}>
                {profile && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.createdBy'])} span={3}>
                    <Avatar
                      style={{
                        backgroundColor: stringToColor(`${profile.user.name.first} ${profile.user.name.last}`),
                        verticalAlign: 'middle',
                        color: 'black',
                      }}
                      size="small"
                    >
                      {getInitials(`${profile.user.name.first} ${profile.user.name.last}`)}
                    </Avatar>
                    {`${profile.user.name.first} ${profile.user.name.last}`}
                  </Descriptions.Item>
                )}
                {template.state && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.status'])} span={3}>
                    <PaymentState state={template.state} />
                  </Descriptions.Item>
                )}
                {profile && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.entity'])} span={3}>
                    {getEntityName(profile)}
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.amount && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.amount'])} span={3}>
                    <Currency
                      value={template.paymentInstructionProperties.amount.toString()}
                      locale={language}
                      currency={template.paymentInstructionProperties.currency}
                    />
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.fee && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.fee'])} span={3}>
                    <Currency
                      value={template.paymentInstructionProperties.fee.toString()}
                      locale={language}
                      currency={template.paymentInstructionProperties.currency}
                    />
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.amount && template.paymentInstructionProperties.fee && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.total'])} span={3}>
                    <Currency
                      value={`${
                        template.paymentInstructionProperties.amount + template.paymentInstructionProperties.fee
                      }`.toString()}
                      locale={language}
                      currency={template.paymentInstructionProperties.currency}
                    />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            <Panel header={intl.formatMessage(pi['pi.details.recipient'])} key="2" className={styles.filterPanel}>
              <Descriptions bordered size="small" className={styles.noBorder}>
                {template.paymentInstructionProperties.beneficiary!.title && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.title'])} span={3}>
                    {template.paymentInstructionProperties.beneficiary!.title}
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.beneficiary!.routingNumber && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.routingNumber'])} span={3}>
                    {template.paymentInstructionProperties.beneficiary!.routingNumber}
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.beneficiary!.accountNumber && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.beneficiary.accountNumber'])} span={3}>
                    <AccountNumber
                      accountNumber={template.paymentInstructionProperties.beneficiary!.accountNumber}
                      routingNumber={template.paymentInstructionProperties.beneficiary!.routingNumber}
                      clearingNetwork={template.paymentInstructionProperties.beneficiary!.clearingNetwork}
                    ></AccountNumber>
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.reference && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.reference'])} span={3}>
                    {template.paymentInstructionProperties.reference}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            <Panel header={intl.formatMessage(pi['pi.details.recurring'])} key="3" className={styles.filterPanel}>
              <Descriptions size="small" className={styles.noBorder} bordered>
                {template.occurrencesRemaining && template.occurrencesRemaining > 1 && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.occurrencesRemaining'])} span={3}>
                    {template.occurrencesRemaining}
                  </Descriptions.Item>
                )}
                {template.interval.infinity && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.infiniteOcurrencesRemaining'])} span={3}>
                    {intl.formatMessage(pi['pi.infiniteOcurrencesRemainingOptionYes'])}
                  </Descriptions.Item>
                )}
                {template.interval.unit &&
                  template.interval.value &&
                  template.occurrencesRemaining &&
                  template.occurrencesRemaining > 1 && (
                    <Descriptions.Item label={getIntervalString(template.interval)} span={3}>
                      <span data-testid={`recurring-${template.interval.value}-${template.interval.unit}`}>
                        {getIntervalString(template.interval)}
                      </span>
                    </Descriptions.Item>
                  )}
                {template.dateDueNext && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.dateDue'])} span={3}>
                    <Date value={template.dateDueNext} locale={language} />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            {template.signatures && (
              <Panel header={intl.formatMessage(pi['pi.details.signatures'])} key="4" className={styles.filterPanel}>
                <Descriptions size="small" className={styles.noBorder} bordered>
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.requireSignatureBy'])} span={3}>
                    {template.signatures.signatureRequests.map((approversRound: Approver[], index: number) => (
                      <div className={styles.approverRounds} key={index}>
                        {template.signatures!.signatureOrder === 'sequential' && (
                          <span className={styles.roundLabel}>
                            {intl.formatMessage(pi['pi.details.round'])}
                            &nbsp;
                            {index + 1}: &nbsp;
                          </span>
                        )}
                        <div>
                          {approversRound.map((approver: Approver) => (
                            <div
                              key={`approver-${approver.userId}`}
                            >{`${approver.name.first} ${approver.name.last}`}</div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.signedBy'])} span={3}>
                    {template.signatures.signedBy.map((approversRound: Approver[], index: number) => (
                      <div className={styles.approverRounds} key={index}>
                        {template.signatures!.signatureOrder === 'sequential' && (
                          <span className={styles.roundLabel}>
                            {intl.formatMessage(pi['pi.details.round'])}
                            &nbsp;
                            {index + 1}: &nbsp;
                          </span>
                        )}
                        <div>
                          {approversRound.map((approver: Approver) => (
                            <div
                              key={`approver-${approver.userId}`}
                            >{`${approver.name.first} ${approver.name.last}`}</div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )}
            {template.paymentInstructionProperties.attachments && (
              <Panel header={intl.formatMessage(pi['pi.details.attachment'])} key="5" className={styles.filterPanel}>
                <Descriptions.Item label={intl.formatMessage(pi['pi.details.attachment'])} span={3}>
                  {template.paymentInstructionProperties.attachments.map((attachment: Attachment) => (
                    <div key={`attachment-${attachment.attachmentId!}`}>
                      <Button
                        type="link"
                        onClick={() => void handleDownloadAttachment(templateId, attachment.attachmentId!)}
                      >
                        {attachment.filename}
                      </Button>
                    </div>
                  ))}
                </Descriptions.Item>
              </Panel>
            )}
            <Panel header={intl.formatMessage(pi['pi.details.paymentCards'])} key="6" className={styles.filterPanel}>
              <Descriptions size="small" className={styles.noBorder} bordered>
                {template.paymentInstructionProperties.source?.title && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardTitle'])} span={3}>
                    {template.paymentInstructionProperties.source.title}
                  </Descriptions.Item>
                )}
                {template.paymentInstructionProperties.source && (
                  <Descriptions.Item label={intl.formatMessage(pi['pi.details.cardNubmer'])} span={3}>
                    {`${template.paymentInstructionProperties.source.typeProperties.bin} •••• •••• ${template.paymentInstructionProperties.source.typeProperties.last4}`}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
            {!isOverview && (
              <Panel key="7" header={intl.formatMessage(pi['pi.actions'])} className={styles.filterPanel}>
                <Descriptions size="small" className={cx(styles.noBorder, styles.actionPanel)}>
                  <Descriptions.Item span={3}>
                    {handleRemovePayment && (
                      <ACL requiredACL={[{ kind: 'entity', action: 'delete' }]} barracksId={template.profileId}>
                        <Button
                          danger={true}
                          icon={<StopOutlined />}
                          type="link"
                          data-testid="delete-button"
                          disabled={!['ACTIVE', 'REQUIRE_SIGNATURE', 'READY'].includes(template.state!)}
                          onClick={() => handleRemovePayment(template.id!)}
                        >
                          {intl.formatMessage(action['action.pi.stop'])}
                        </Button>
                      </ACL>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            )}
          </Collapse>
        </div>
      )}
    </Loader>
  )
}

export default PaymentInstructionTemplateDetails
